import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ControlPanel from './ControlPanel';
import '../styles/KanbanBoard.css';
import TodoIcon from '../images/To-do.svg';
import InProgressIcon from '../images/in-progress.svg';
import BacklogIcon from '../images/Backlog.svg';
import DoneIcon from '../images/Done.svg';
import ExtraIcon1 from '../images/add.svg';
import ExtraIcon2 from '../images/3 dot menu.svg';
import SignalIcon from '../images/Img - High Priority.svg';
import FeatureRequestIcon from '../images/Display.svg';
import dotmenu from '../images/3 dot menu.svg';
import todo from '../images/To-do.svg';

const KanbanBoard = () => {
  const [tickets, setTickets] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({ priority: '', status: '', assignee: '' });
  const [filteredTickets, setFilteredTickets] = useState({
    Todo: [],
    InProgress: [],
    Backlog: [],
    Done: [],
  });

  const priorities = [
    { value: '1', label: 'Low' },
    { value: '2', label: 'Medium' },
    { value: '3', label: 'High' },
  ];

  const statuses = ['Todo', 'In Progress', 'Backlog', 'Done'];

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await axios.get('https://api.quicksell.co/v1/internal/frontend-assignment');
        setTickets(response.data.tickets || []);
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    filterTickets();
  }, [tickets, filters]);

  const filterTickets = () => {
    const applyFilters = (ticket) => {
      return (
        (filters.priority === '' || ticket.priority === parseInt(filters.priority)) &&
        (filters.status === '' || ticket.status === filters.status) &&
        (filters.assignee === '' || ticket.userId === filters.assignee)
      );
    };

    const groupedTickets = {
      Todo: tickets.filter(ticket => ticket.status === 'Todo' && applyFilters(ticket)),
      InProgress: tickets.filter(ticket => ticket.status === 'In progress' && applyFilters(ticket)),
      Backlog: tickets.filter(ticket => ticket.status === 'Backlog' && applyFilters(ticket)),
      Done: tickets.filter(ticket => ticket.status === 'Done' && applyFilters(ticket)),
    };

    setFilteredTickets(groupedTickets);
  };

  // Update filter state based on filter selection
  const onFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const getUserById = (userId) => users.find(user => user.id === userId);

  const getProfileBackgroundColor = (userId) => {
    const colors = ['#FF5733', '#33A1FF', '#33FF57', '#FF33A1'];
  
    // Convert the string ID to a number using a hashing function
    const hashedId = Math.abs(userId.hashCode()) % colors.length;
  
    // Use the hashed ID as the index to select a color
    return colors[hashedId];
  };
  
  // Helper function to calculate the hash code of a string
  String.prototype.hashCode = function() {
    let hash = 0;
    for (let i = 0; i < this.length; i++) {
      hash = ((hash << 5) - hash) + this.charCodeAt(i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  };




  return (
    <div className="kanban-board">
      <ControlPanel
        filters={filters}
        statuses={statuses}
        priorities={priorities}
        onFilterChange={onFilterChange}
      />
      <div className="kanban-columns">
        {Object.keys(filteredTickets).map((status) => (
          <div key={status} className="kanban-column">
            <h2>
              <div className="status-container">
                <img src={{ Todo: TodoIcon, InProgress: InProgressIcon, Backlog: BacklogIcon, Done: DoneIcon }[status]} alt={`${status} icon`} className="status-icon" />
                {status} <span>{filteredTickets[status].length}</span>
              </div>
              <div className="extra-icons">
                <img src={ExtraIcon1} alt="Add icon" className="extra-icon" />
                <img src={ExtraIcon2} alt="Menu icon" className="extra-icon" />
              </div>
            </h2>

            <div className="kanban-tickets">
              {filteredTickets[status].map(ticket => {
                const assignee = getUserById(ticket.userId);
                return (
<div key={ticket.id} className="kanban-ticket">
  <div className="ticket-id">{ticket.id}</div>
  <div className="ticket-header">
  <h3 className="ticket-title">
    {ticket.title}
  </h3>
  {assignee && (
    <div
      className="assignee-initials"
      style={{ backgroundColor: getProfileBackgroundColor(assignee.id) }}
    >
      {`${assignee.name[0]}${assignee.name.split(' ')[1]?.[0] || ''}`}
    </div>
  )}
</div>

  <div className="ticket-details">
    <div className="left-icons">
      <div className="icon-border">
        <img src={dotmenu} alt="Priority signal icon" className="priority-signal" />
      </div>
      <div className="feature-request-tag">
        <img src={todo} alt="Feature request icon" className="feature-icon" />
        Feature Request
      </div>
    </div>
  </div>
</div>

                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KanbanBoard;
